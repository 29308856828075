
export function openTab(tabName) {
    return handle(tabName, "content")
}
export function openExample(tabName) {
    return handle(tabName, "example")
}

function handle(tabName, className){
    const contentTabs = document.getElementsByClassName(className +"-tab");
    for (let i = 0; i < contentTabs.length; i++) {
        contentTabs[i].style.display = "none";
    }

    const tablinks = document.getElementsByClassName(className +"-lnk");
    for (let i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace("is-active", "");
    }

    const activeElement = document.getElementById(tabName)
    activeElement.style.display = "block";

    const tabElement = document.getElementById(tabName + 'Tab');
    tabElement.className += " is-active";
}
