
export function checkEmail(email){
    let ret =  String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

    return ret !== null;
}

export function sendEmail(email){
    fetch("https://chat.ausdertechnik.de/hooks/5rjo387r7tdampw99iiaso7xcc", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "text/plain"
        },
        body: JSON.stringify({
            username: "Bot",
            icon_emoji: ":mailbox_with_mail:",
            text: "Newsletter registrierung: **"+ email +"**"
        })
    }).then((res)=>{
        if( res.status > 299 ){
            console.error("Registration did not succeed.")
        }
    });
}