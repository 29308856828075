
import {openTab, openExample} from "./tabs";
import {checkEmail, sendEmail} from "./newsletter";

document.addEventListener('DOMContentLoaded', () => {
    // Burger Menu
    // ---------------------
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {

            // Get the target from the "data-target" attribute
            const target = el.dataset.target;
            const $target = document.getElementById(target);

            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            el.classList.toggle('is-active');
            $target.classList.toggle('is-active');

        });
    });

    // Tabbar
    // ---------------------
    const $tabElements = Array.prototype.slice.call(document.querySelectorAll('.content-lnk'), 0);
    $tabElements.forEach( el => {
        el.addEventListener('click', () => {
           const targetID = el.dataset.for;
           openTab(targetID);
        });
    });
    const $exampleElements = Array.prototype.slice.call(document.querySelectorAll('.example-lnk'), 0);
    $exampleElements.forEach( el => {
        el.addEventListener('click', () => {
            const targetID = el.dataset.for;
            openExample(targetID);
        });
    });

    // Newsletter
    const indicator = document.getElementById("newsletterEmailCorrect");
    const $emailField = document.getElementById("newsletter");
    if($emailField){
        $emailField.addEventListener('input', (evt) => {
            const value = $emailField.value;
            if( checkEmail(value) === false ){
                indicator.className = "fas fa-xmark"
            } else {
                indicator.className = "fas fa-check"
            }
        })
        $emailField.addEventListener('blur', (evt) => {
            const email = evt.target.value;
            if( checkEmail(email) === true ) {
                sendEmail(email);
                evt.target.value = "";
            }
        });
    }
});
